<template>
  <v-card outlined class="mt-0">
    <v-card-title class="pb-2 pt-2 pl-2 d-flex grey lighten-4">
      <span class="text-truncate">
        {{ $t("Components and Dependencies") }}
      </span>
      <v-spacer />
    </v-card-title>
    <v-card-text>
      <v-row class="mt-3" dense>
        <v-col cols="12">
          <v-progress-linear v-show="loading" style="margin:0px" :indeterminate="loading" color="primary" />
        </v-col>
        <v-col cols="12">
          <v-data-table
            dense
            locale="en"
            hide-default-footer
            item-key="_id"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            multi-sort
            :page.sync="page"
            :items-per-page.sync="itemsPerPage"
            :server-items-length="totalResults"
            :search="search"
            :headers="headers"
            :items="records"
            class="elevation-1"
          >
            <template #item.id="{ item }">
              <router-link :to="'/cwe/' + item.id">
                {{ item.id }}
              </router-link>
            </template>
            <template #expanded-item="{ headers, item }">
              <td :colspan="headers.length" class="px-0">
                <!-- {{ item.description }} -->
              </td>
            </template>
            <template #body.prepend="{ headers }">
              <tr v-if="!$vuetify.breakpoint.xs" style="border-bottom: thin solid rgba(0, 0, 0, 0.12);">
                <td v-for="header in headers" :key="header.value" class="px-1">
                  <div
                    v-if="
                      header.value != 'product_type' &&
                        header.value != 'vce_entries' &&
                        header.value != 'data-table-expand'
                    "
                    style="width:100%"
                    class="d-inline-flex"
                  >
                    <span v-show="false">{{ render }}</span>
                    <v-text-field
                      v-model="filtersTM[header.value]"
                      style="width:100%"
                      class="mb-1"
                      clearable
                      dense
                      hide-details
                      @keydown.enter="
                        page = 1
                        getRecords()
                      "
                      @click:clear="
                        filtersTM[header.value] = ''
                        page = 1
                        getRecords()
                      "
                    />
                    <v-btn
                      class="mr-0 mt-1"
                      icon
                      small
                      @click="
                        page = 1
                        getRecords()
                      "
                    >
                      <v-icon>mdi-magnify</v-icon>
                    </v-btn>
                  </div>
                  <!-- {{ header.value }} -->
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
        <v-col class="mt-2 flex-wrap" cols="12">
          <div style="width:200px" class="float-sm-left d-flex mb-2">
            <v-select
              v-model="itemsPerPage"
              style="width:85px"
              class="px-2 py-0 my-0 "
              :items="rowsPerPageItems"
              flat
              hide-details
              @change="
                page = 1
                getRecords()
              "
            />
            <span class="mt-2  text-body-1">
              {{ (page - 1) * itemsPerPage + 1 }}-{{
                totalResults > page * itemsPerPage ? page * itemsPerPage : totalResults
              }}
              {{ "of" }} {{ totalResults }}
            </span>
          </div>
          <v-pagination
            v-model="page"
            class="d-flex"
            :length="Math.ceil(totalResults / itemsPerPage)"
            total-visible="7"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import axios from "../../../plugins/axios"
export default {
  name: "DashboardDashboard",
  components: {},
  data() {
    return {
      path: "",
      records: [],
      loading: true,
      render: 1,
      search: "",
      page: 1,
      sortBy: [],
      sortDesc: [],
      expanded: [],
      itemsPerPage: 20,
      totalResults: 10,
      rowsPerPageItems: [
        { text: 10, value: 10 },
        { text: 20, value: 20 },
        { text: 30, value: 30 },
        { text: 50, value: 50 }
      ],
      filtersTM: {
        name: "",
        type: "",
        details: ""
      },
      filtresFields: {
        name: "string",
        type: "string",
        details: "string"
      },
      headers: [
        { text: "Name", value: "name" },
        { text: "Type", value: "type" },
        { text: "Details", value: "details" }
      ]
    }
  },
  computed: {},
  watch: {
    $route(to) {
      this.refreshPage()
    },
    page() {
      this.getRecords()
    },
    sortDesc() {
      this.getRecords()
    }
  },
  created() {
    this.$log("routec ", this.$route)
    this.refreshPage()
  },
  methods: {
    refreshPage() {
      if (this.$route.name == "Components") {
        this.getRecords()
      }
    },
    getRecords() {
      //this.$log("filtres ", this.filtersTM)
      let where = ""
      for (const filter in this.filtersTM) {
        if (this.filtersTM[filter] != "" && this.filtersTM[filter] != null) {
          if (this.filtresFields[filter] == "number") {
            where += ',"' + filter + '":' + this.filtersTM[filter] + ""
          } else {
            where += ',"' + filter + '":{"$regex":"' + encodeURIComponent(this.filtersTM[filter]) + '","$options":"i"}'
          }
        }
      }
      if (where != "") {
        where = "&where={" + where.substr(1) + "}"
      }
      let sort = "["
      this.sortBy.map((s, i) => {
        sort += '("' + s + '",' + (this.sortDesc[i] ? "-1" : "1") + "),"
      })
      if (sort.length > 1) {
        sort = "&sort=" + sort.substr(0, sort.length - 1)
        sort += "]"
      } else {
        sort = ""
      }
      this.records = []
      axios
        .get("nvd_components" + "?max_results=" + this.itemsPerPage + "&page=" + this.page + where + sort)
        .then(response => {
          //this.$log("vulnerability ", response)
          this.totalResults = response.data._meta.total
          this.records = response.data._items
          this.loading = false
        })
        .finally(() => {
          this.loading = false
        })
    },
    resolvePath(path, obj) {
      return path.split(".").reduce(function(prev, curr) {
        return prev ? prev[curr] : null
      }, obj || self)
    }
  }
}
</script>
<style scoped>
.v-data-table__expanded.v-data-table__expanded__content {
  box-shadow: none !important;
}
</style>
